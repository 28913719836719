import {graphql} from 'gatsby';

import Building from '../screens/building';
export default Building;

export const query = graphql`
  query {
    allStrapiConstructions {
      edges {
        node {
          id
          date
          image {
            childImageSharp {
              fixed(width: 790, height: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
