import React from "react";
import styled from "styled-components";
import { compose, withHandlers, withStateHandlers } from "recompose";
import * as HOC from "@p14/HOC";
import config from "@p14/config";

export const Root = styled.section`
  max-width: 790px;
  margin: 0 auto 40px;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  font-size: 0;
  height: 499px;
  white-space: nowrap;
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  transition: transform 0.5s ease 0s;
  will-change: transform;

  @media ${config.media.mobile} {
    height: 300px;
  }
`;

export const Slide = styled.article`
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 100%;

  & img {
    max-width: 100%;
  }
`;

export const Arrow = styled.button`
  padding: 0;
  outline: none;
  background-color: none;
  border: none;
  position: absolute;
  top: 0;
  height: 499px;
  width: 100px;
  left: ${(props) => (props.direction === "left" ? "-100px" : "auto")};
  right: ${(props) => (props.direction === "right" ? "-100px" : "auto")};
  transform: ${(props) =>
    props.direction === "left" ? "rotate(180deg)" : "rotate(0deg)"};

  @media ${config.media.tablet} {
    display: none;
  }
`;

export const Pagination = styled.nav`
  height: 6px;
  text-align: center;
  font-size: 0;
  white-space: nowrap;
  margin: 10px 0 8px;
`;

export const PaginationItem = styled.button`
  display: inline-block;
  vertical-align: top;
  background-color: none;
  padding: 0;
  margin: 0 12px;
  border: none;
  outline: none;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${(props) => (props.isActive ? "#b48b72" : "#ddd")};
  transition: background-color 0.3s ease 0s;
  will-change: background-color;
`;

export const Captions = styled.div`
  position: relative;
  min-height: 16px;
`;

export const Svg = () => (
  <svg width="21" height="41">
    <path
      d="M.5 40.5l20-20M.5.5l20 20"
      stroke="#B48B72"
      fill="none"
      strokeLinecap="square"
    />
  </svg>
);

export const Caption = styled.p`
  margin: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 12px;
  color: ${config.colors.main};
  white-space: nowrap;
  transform: translate(-50%, -50%);
  user-select: ${(props) => (props.isVisible ? "inherit" : "none")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease 0s;
  will-change: opacity;

  @media ${config.media.mobile} {
    font-size: 12px;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Slider = ({
  slides,
  activeIndex,
  followedX,
  onNext,
  onPrev,
  onPaginationItemClick,
}) => (
  <Root>
    <Container>
      <Wrapper
        id="architecture-slider"
        isDragging={Boolean(followedX)}
        style={{
          transform: `translateX(${activeIndex * 100 * -1 + followedX * -1}%)`,
          transition: followedX ? "none" : "",
        }}
      >
        {slides.map((slide, index) =>
          slide.image.childImageSharp ? (
            <Slide key={slide.id}>
              <Img
                draggable={false}
                src={slide.image.childImageSharp.fixed.src}
              />
            </Slide>
          ) : null
        )}
      </Wrapper>

      <Pagination>
        {slides.map((slide, index) => (
          <PaginationItem
            key={index}
            isActive={index === activeIndex}
            onClick={() => onPaginationItemClick(index)}
          />
        ))}
      </Pagination>

      {
        <Captions>
          {slides.map((slide, index) => (
            <Caption key={index} isVisible={index === activeIndex}>
              {slide.date}
            </Caption>
          ))}
        </Captions>
      }
    </Container>

    <Arrow direction="left" onClick={onPrev}>
      <Svg />
    </Arrow>
    <Arrow direction="right" onClick={onNext}>
      <Svg />
    </Arrow>
  </Root>
);

export const enhance = compose(
  withStateHandlers(({ activeIndex = 0 }) => ({ activeIndex }), {
    onNext:
      ({ activeIndex }, { slides }) =>
      () => ({
        activeIndex: activeIndex + 1 > slides.length - 1 ? 0 : activeIndex + 1,
      }),
    onPrev:
      ({ activeIndex }, { slides }) =>
      () => ({
        activeIndex: activeIndex - 1 < 0 ? slides.length - 1 : activeIndex - 1,
      }),
    onPaginationItemClick: () => (index) => ({ activeIndex: index }),
  }),
  withHandlers({
    onFollowTouchEnd: (props) => (followedX) =>
      followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null,
  }),
  HOC.withTouch("architecture-slider")
);

export default enhance(Slider);
