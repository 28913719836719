import React from 'react';
import {compose} from 'recompose';
import styled from 'styled-components';
import Layout from '@p14/layouts';
import * as HOC from '@p14/HOC';
import * as Components from '@p14/components';
import config from '@p14/config';
import Slider from './Slider';
import {reverse} from 'ramda';

export const Footer = styled.p`
  margin: 0 auto 30px;
  max-width: 784px;
  font-family: Geometria;
  font-size: 12px;
  color: #000;
  line-height: 14px;
  text-align: center;
  color: ${config.colors.text};
`;

export const Building = ({data, tr}) => (
  <Layout>
    <Components.Container>
      <Components.H1 dangerouslySetInnerHTML={{__html: 'Строительство'}}/>
    </Components.Container>
    <Slider slides={reverse(data.allStrapiConstructions.edges.map(({node}) => node))}/>
    <Footer dangerouslySetInnerHTML={{__html: 'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.'}}/>
  </Layout>
);

export default Building;
